.App {
  position: relative;
  min-height: 100vh;
  min-width: 100vw;
  padding: 0 15px 0 0;
}

.activity-search .field {
  display: inline;
}

.activity-search .field .ui.input {
  display: inline;
}

.ui.loading.segment::after {
  display: none !important;
}